import Layout from '../components/layout'
import React from 'react'
import { Link } from 'gatsby'

const codeStyles = {
	color: "#8A6534",
	padding: 4,
	backgroundColor: "#FFF4DB",
	fontSize: "1.25rem",
	borderRadius: 4,
}

export default function NotFoundPage({ location }) {

	function alternateLink(): string|null {
		console.log(`location: ${location}`)
		if (location.pathname.endsWith(".html")) {
			return location.pathname.replace(".html", "/")
		}
		return null
	}

	return (
		<Layout pageTitle="Not Found">
			<p>
				Outdated link 😔
				Please go to the <Link to="/">home page</Link>
				{alternateLink() === null ? <></> : <>
					{" "}or try <Link to={alternateLink()}>this link</Link>
				</>}
				<br />
				{process.env.NODE_ENV === "development" ? (
				<>
					<br />
					Try creating a page in <code style={codeStyles}>src/pages/</code>.
					<br />
				</>
				) : null}
			</p>
		</Layout>
	)
}
